/* global SVP, PLAYER_URL, API_URL, TOKEN_URL, THUMBNAILS_URL */
import 'regenerator-runtime/runtime';
import 'core-js/modules/web.dom-collections.for-each';
import 'core-js/modules/es.object.keys';
import 'core-js/modules/es.map';
import 'core-js/modules/es.string.includes';
import 'core-js/modules/es.array.iterator';
import merge from 'lodash.merge';
import get from 'lodash.get';
import set from 'lodash.set';
import getVendorConfig from './get-vendor-config';
import { assetAttribute, MIN_PLAYER_HEIGHT, nouiSkinConfig, autoplayViewableConfig, defaultRecommended } from './config';
import { secondsToTime } from './util';

function attachPlayer() {
    window.addEventListener('onSvpPlayerReady', () => {
        document.querySelectorAll(`[${assetAttribute}]`).forEach((element, idx) => {
            const playerContainer = document.createElement('div');
            playerContainer.id = `svp-player-${idx}`;
            element.appendChild(playerContainer);
            const { provider, id, noui, autoplayViewable, pluginTracking, recommended, dvr, sharing, videoPreview, ...config } = JSON.parse(
                decodeURIComponent(element.getAttribute(assetAttribute)),
            );

            const nextAssetId = get(recommended, 'next.asset.id', get(recommended, 'next.asset'));
            const playerHeight = config.height || element.offsetHeight;
            const enablePluginMuted = playerHeight > MIN_PLAYER_HEIGHT;
            const vendorConfig = getVendorConfig(provider, {
                autoplay: autoplayViewable,
                mute: config.mute,
                nextAssetId,
                playNext: get(recommended, 'autoplay'),
                pluginTracking,
                enablePluginMuted,
                recommended: merge(defaultRecommended, recommended),
            });
            if (get(config, 'settings.na') === true) {
                delete vendorConfig.adn;
            }

            const locale = get(vendorConfig, 'locale');
            if (locale && !SVP.Player.defaults.locale) {
                SVP.Player.defaults = {
                    locale,
                };
            }
            const playerConfig = merge(
                vendorConfig,
                {
                    api: API_URL,
                    tokenUrl: TOKEN_URL,
                    thumbnailsUrl: THUMBNAILS_URL,
                    id: Number(id),
                    vendor: provider,
                    node: playerContainer.id,
                    recommended,
                    sharing,
                    videoPreview,
                    ...(dvr === false ? { minDvrWindow: 172800 } : {}),
                },
                config,
                noui && playerHeight > MIN_PLAYER_HEIGHT && nouiSkinConfig,
                autoplayViewable && autoplayViewableConfig,
            );

            if (autoplayViewable) {
                const { adn } = playerConfig;
                playerConfig.adn = async (options, stream, defaults, player) => {
                    const adnConfig = adn ? await adn.call(playerConfig, options, stream, defaults, player) : {};
                    set(adnConfig, 'options.autoplayMuted', true);
                    return adnConfig;
                };
            }

            return new SVP.Player(playerConfig);
        });
    });
}

/**
 *
 * @param {URLSearchParams} searchParams
 * @returns {Object}
 */
function getParamsSearchParams(searchParams) {
    const paramsMap = {
        id: 'id',
        provider: 'provider',
        noui: 'noui',
        play: 'autoplayViewable',
        start: 'time',
    };

    const params = {};
    Object.entries(paramsMap).forEach(([key, value]) => {
        if (searchParams.has(key)) {
            set(params, value, searchParams.get(key) || true);
        }
    });

    if (searchParams.has('loop')) {
        set(params, 'repeat', searchParams.get('loop') === 'true');
    }
    if (searchParams.has('noads')) {
        set(params, 'settings.na', searchParams.get('noads') === 'true');
    }
    if (searchParams.has('next')) {
        set(params, 'recommended.next', searchParams.get('next') === 'true');
    }
    if (searchParams.has('start')) {
        set(params, 'start', secondsToTime(Number(searchParams.get('start'))));
    }
    if (searchParams.has('sharing')) {
        set(params, 'sharing', searchParams.get('sharing') === 'true');
    }
    if (searchParams.has('videoPreview')) {
        const videoPreviewValue = searchParams.get('videoPreview');
        set(params, 'videoPreview', videoPreviewValue === 'true' || videoPreviewValue);
    }
    return params.id && params.provider && params;
}

function loadPlayer(d, s, id) {
    const fjs = d.getElementsByTagName(s)[0];
    if (d.getElementById(id)) {
        return;
    }

    const js = d.createElement(s);
    js.id = id;
    // beta is latest semi-stable channel, read more in section above
    // for production it's required to use latest release if possible
    js.src = `${PLAYER_URL}/player.min.js`;
    js.async = true;
    try {
        // eslint-disable-next-line no-unused-expressions, no-eval, no-sequences
        eval('async () => {}'), (js.src = js.src.replace('player.min', 'player.next.min'));
    } catch (a) {} // eslint-disable-line no-empty
    fjs.parentNode.insertBefore(js, fjs);
}

(() => {
    try {
        const searchParams = new URLSearchParams(document.location.search);
        const iframeParams = searchParams.has('params') ? searchParams.get('params') : JSON.stringify(getParamsSearchParams(searchParams));

        if (iframeParams) {
            const width = document.documentElement.clientWidth ? `${document.documentElement.clientWidth}px` : '100%';
            const height = document.documentElement.clientHeight ? `${document.documentElement.clientHeight}px` : '100%';
            document.write('<div data-svp-asset="'.concat(encodeURIComponent(iframeParams)).concat(`" style="width: ${width}; height: ${height}; overflow: hidden;"></div>`));
        }
        loadPlayer(document, 'script', 'svp-player-sdk');
        attachPlayer();
    } catch (error) {
        console.error(error); // eslint-disable-line no-console
    }
})();
